import React,{Component} from 'react';
import {SpecificationsSection,CustomRow,HeadingLayout,Border,CustomTabList,SliderWrapper,
    ImgButtonLeft,LeftIcon,
    SliderLayout,SpecificationsOuterWrapper,SpecificationsLayout,SpecLayout,SpecIcon,
    SpecWrapper,
    ImgButtonRight,RightIcon,SeparatorLine
} from './specifications.style';
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab, Tabs, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { StaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GatsbyImage } from "gatsby-plugin-image";

class SpecificationsPage extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.state = {
            specificationsList: [],
            specificationsHeading: [],
            specificationsPara: [],
            oldSpec:true,
            newSpec:false
        }
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    doSpecification(item)
    {
        this.setState(
            {
                oldSpec:false,
                newSpec:true,
                specificationsHeading:item.SpecHeading,
                specificationsPara:item.SpecPara,
                specificationsList: item.SpecLayout
            }
        );
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      initialSlide: 1
                    }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                }
            ]
        };
        
        return (
            <SpecificationsSection id="specificationsSection">
                <Container>
                    <Tabs>
                        <CustomRow>
                            <Col lg={3}>
                                <HeadingLayout>
                                    <h3>
                                        {this.props.SpecificationsData.Heading}
                                    </h3>
                                    <Border src={this.props.SpecificationsData.Border} alt=""/>
                                </HeadingLayout>
                                <CustomTabList>
                                {
                                    this.props.SpecificationsData.TabList.map((item,idx) => {
                                        return <Tab onClick={this.doSpecification.bind(this,item)}>{item.Tab}</Tab>
                                    })
                                }
                                </CustomTabList>
                            </Col>
                            <Col lg={9}>
                            {
                                this.props.SpecificationsData.TabList.map((itemTab,idxTab) => {
                                return (
                                    <TabPanel>
                                                <CustomRow>
                                                    <Col lg={8}>
                                                        <SliderWrapper>
                                                            <ImgButtonLeft onClick={this.previous} aria-label="Prev Button">
                                                                <LeftIcon />
                                                            </ImgButtonLeft>
                                                            <Slider ref={c => (this.slider = c)} {...settings}>
                                                            {
                                                                itemTab.TabPanel.map((itemPanel,idxPanel) => {
                                                                return (
                                                                    <SliderLayout>
                                                                                <GatsbyImage
                                                                                    image={itemPanel.Img.childImageSharp.gatsbyImageData}
                                                                                    alt=""
                                                                                    className="propertyImg" />
                                                                            </SliderLayout>
                                                                );
                                                                    })
                                                            }
                                                            </Slider>
                                                            <ImgButtonRight onClick={this.next} aria-label="Next Button">
                                                                <RightIcon />
                                                            </ImgButtonRight>
                                                        </SliderWrapper>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <SpecificationsOuterWrapper>
                                                            <SpecificationsLayout>
                                                                
                                                                <h4>
                                                                {itemTab.SpecHeading} 
                                                                </h4>
                                                                <p>
                                                                {itemTab.SpecPara} 
                                                                </p>
                                                                {
                                                                    itemTab.SpecLayout.map((specItem, specIdx)=>{
                                                                        return <SpecWrapper>
                                                                            <SpecLayout>
                                                                                <SpecIcon src={specItem.SpecIcon} alt=""/>
                                                                                <p>
                                                                                {specItem.SpecText} 
                                                                                </p>
                                                                            </SpecLayout>
                                                                            {
                                                                                specIdx !== 4 &&
                                                                                <SeparatorLine />
                                                                            }
                                                                            
                                                                        </SpecWrapper>
                                                                    })
                                                                }
                                                            </SpecificationsLayout>
                                                        </SpecificationsOuterWrapper>
                                                    </Col>
                                                </CustomRow>
                                        </TabPanel>
                                );
                                })
                            }
                            </Col>
                        </CustomRow>
                    </Tabs>
                </Container>
            </SpecificationsSection>
        );
    }
}


export default () => (
<StaticQuery
    query={graphql`{
  realestatePage2Json {
    Specifications {
      Heading
      Border
      TabList {
        Tab
        SpecHeading
        SpecPara
        TabPanel {
          Img {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        SpecLayout {
          SpecIcon
          SpecText
        }
      }
    }
  }
}
`}

    render={(data) => (
        <SpecificationsPage SpecificationsData={data.realestatePage2Json.Specifications}/>
    )}
/>
)