import React from 'react';
import {AboutSection,CustomRow,TextLayout,HeadingLayout,Border,Description1,
    List,ListItem,CheckCircleIcon,ImageHolder
} from './about.style';
import {Container,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';

const About = () => {
    const JSONData  = useStaticQuery(graphql`{
  realestatePage2Json {
    About {
      Heading
      Border
      Description1
      SubHeading
      ListText1
      ListText2
      ListText3
      Img {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`);
    const AboutData = JSONData.realestatePage2Json.About;

    return (
        <AboutSection id="aboutSection">
            <Container>
                <CustomRow>
                    <Col md={12} lg={6}>
                        <TextLayout>
                            <HeadingLayout>
                                <h3>
                                {AboutData.Heading} 
                                </h3>
                                <Border src={AboutData.Border} alt=""/>
                            </HeadingLayout>
                            <p>
                            {AboutData.Description1} 
                            </p>
                            <h5>{AboutData.SubHeading}</h5>
                            <List>
                                <ListItem>
                                    <CheckCircleIcon />
                                    <p>{AboutData.ListText1}</p>
                                </ListItem>
                                <ListItem>
                                    <CheckCircleIcon />
                                    <p>{AboutData.ListText2}</p>
                                </ListItem>
                                <ListItem>
                                    <CheckCircleIcon />
                                    <p>{AboutData.ListText3}</p>
                                </ListItem>
                            </List>
                        </TextLayout>
                    </Col>
                    <Col md={12} lg={6}>
                        <ImageHolder>
                            <GatsbyImage image={AboutData.Img.childImageSharp.gatsbyImageData} alt="" />
                        </ImageHolder>
                    </Col>
                </CustomRow>
            </Container>
        </AboutSection>
    );
}

export default About;