import styled from 'styled-components';
import {device} from '../Common/device';
// import BackgroundImg from '../../../assets/home-images/banner.jpg'

export const BannerSection = styled.section`
    min-height:100vh;
`;

export const BannerSliderWrapper = styled.div`

    .slick-list{
        line-height:0;
    }

    .slick-active{
        z-index:999;
    }
`;

export const BannerSliderLayout = styled.div`
    position:relative;

    // ANIMATIONS
    @-webkit-keyframes leaves {
        0% {
            -webkit-transform: scale(1.0);
        }
        100% {
            -webkit-transform: scale(1.2);
        }
    }

    @keyframes leaves {
        0% {
            transform: scale(1.0);
        }
        100% {
            transform: scale(1.2);
        }
    }
`;

export const BannerImageHolder = styled.div`
    position:relative;
    width:100%;
    min-height:100vh;
    overflow:hidden;

    .bannerImage{
        min-height:100vh;
        -webkit-animation: leaves 10s ease-in-out infinite alternate;
        animation: leaves 10s ease-in-out infinite alternate;
    }
`;

export const BannerContents = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    height:100%;
    display:flex;
    align-items:center;
    line-height:1.5;
    max-width:750px;
    margin:50px auto 0px;
    
    @media ${device.laptop} {
        max-width:600px;
    }

    @media ${device.tablet} {
        max-width:500px;
        margin:30px auto 0px; 
    }

    @media ${device.mobileXL} {
        max-width:400px;
    }
`;

export const BannerTextLayout = styled.div`
    margin:auto;
    padding:25px;
    text-align:center;

    @media ${device.laptop} {
        padding: 10px;
    }

    h6{
        color:#fff;
    }
    h1{
        margin:0px auto 20px;
        color:#fff;
    }
`;

export const NextPrev = styled.div`
    text-align:center;
    position:absolute;
    bottom:40px;
    right:50px;

    @media ${device.tablet}{
        right:0;
        left:0;
    }
`;

export const ImgButton = styled.button`
    line-height:0px;
    padding-bottom:0px;
    border:none;
    background:none;
    outline:none;

    :focus{
        outline:none;
    }
`;

export const PrevImg = styled.img`
    margin:0px 5px;
    cursor:pointer;
    height:50px;
    transition:all .4s;

    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;

export const NextImg = styled.img`
    margin:0px 5px;
    cursor:pointer;
    transition:all .4s;
    height:50px;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;