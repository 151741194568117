import React from 'react';
import {StatsSection,StatsSingle,StatsImg} from './stats.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const Stats = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                realestatePage2Json{
                    Stats{
                        StatsImg1
                        StatsHeading1
                        StatsDesc1
                        StatsImg2
                        StatsHeading2
                        StatsDesc2
                        StatsImg3
                        StatsHeading3
                        StatsDesc3
                        StatsImg4
                        StatsHeading4
                        StatsDesc4
                    }
                }
            }
    `);
    const StatsData = JSONData.realestatePage2Json.Stats;
        return (
        <StatsSection id="statsSection">
            <Container>
                <Row>
                    <Col lg={3} md={3} sm={6} xs={6}>
                        <StatsSingle>
                            <StatsImg 
                                src={StatsData.StatsImg1} 
                                alt=""
                            />
                            <h4>
                            {StatsData.StatsHeading1} 
                            </h4>
                            <p>
                            {StatsData.StatsDesc1}
                            </p>
                        </StatsSingle>
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={6}>
                        <StatsSingle>
                            <StatsImg 
                                src={StatsData.StatsImg2} 
                                alt=""
                            />
                            <h4>
                            {StatsData.StatsHeading2} 
                            </h4>
                            <p>
                            {StatsData.StatsDesc2}
                            </p>
                        </StatsSingle>
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={6}>
                        <StatsSingle>
                            <StatsImg 
                                src={StatsData.StatsImg3} 
                                alt=""
                            />
                            <h4>
                            {StatsData.StatsHeading3} 
                            </h4>
                            <p>
                            {StatsData.StatsDesc3}
                            </p>
                        </StatsSingle>
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={6}>
                        <StatsSingle>
                            <StatsImg 
                                src={StatsData.StatsImg4} 
                                alt=""
                            />
                            <h4>
                            {StatsData.StatsHeading4} 
                            </h4>
                            <p>
                            {StatsData.StatsDesc4}
                            </p>
                        </StatsSingle>
                    </Col>
                </Row>
            </Container>
        </StatsSection>
    );
}

export default Stats;