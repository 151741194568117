import styled from 'styled-components';
import {device} from './device';

export const Commonbtn = styled.a`
    background: #f4ba10;
    color: #282828;
    padding: 10px 40px 12px;
    text-decoration:none;
    border-radius: 5px;
    display:inline-block;
    text-transform:uppercase;
    cursor:pointer;

    :hover{
        background:#c39409;
    }

    @media ${device.mobileXL} {
        padding: 10px 30px;
    }
`;


export const SectionHeading = styled.h3`
    text-align:center;
    line-height:1;

    @media ${device.laptopM} {
        line-height:1;
    }

    @media ${device.mobileXL} {
        line-height:1;
    }
`;
