import styled from 'styled-components';
import {device} from '../Common/device'

export const GallerySection = styled.section`
    background:#f5f5f5;
`;

export const GalleryImageWrapper = styled.div`
    display:flex;
    flex-wrap:wrap;

    @media ${device.tablet} {
    }
`;

export const ImageHolder = styled.div`
    width:25%;
    cursor:pointer;

    @media ${device.tablet} {
        width:50%;
    }
`;