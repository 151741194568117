import React,{Component} from 'react';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {SliderOuterWrapper,OurAgentsSection,HeadingLayout,Heading,Border,ImgButtonLeft,LeftIcon,
    OurAgentsSliderCol,OurAgentsImgFigure,
    OurAgentsDescLayout,OurAgentsTel,OurAgentsSocial,FbIcon,TwitterIcon,LinkedinIcon,
    InstaIcon,OurAgentsLayout,ImgButtonRight,RightIcon} from './ouragents.style';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

class OurAgentsPage extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render(){

        var settings = {
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                }
            ]
        };

        return (
            <OurAgentsSection id="agentsSection">
                <Container>

                    <HeadingLayout>
                        <Heading>
                        {this.props.OurAgentsData.Heading} 
                        </Heading>
                        <Border src={this.props.OurAgentsData.Border} alt=""/>
                    </HeadingLayout>

                    <SliderOuterWrapper>

                        <ImgButtonLeft onClick={this.previous} aria-label="Prev Button">
                            <LeftIcon />
                        </ImgButtonLeft>

                        <Slider ref={c => (this.slider = c)}  {...settings}>
                        {
                            this.props.OurAgentsData.OurAgentsSlider.map((item,idx) => {
                            return (
                                <OurAgentsSliderCol>
                                    <OurAgentsLayout>

                                        <OurAgentsImgFigure>
                                            <GatsbyImage
                                                image={item.OurAgentsSliderImg.childImageSharp.gatsbyImageData}
                                                alt=""
                                                className="agentImg" />
                                        </OurAgentsImgFigure>

                                        <OurAgentsDescLayout>
                                            <h5>{item.OurAgentsName}</h5>
                                            <OurAgentsTel href={"tel:"+item.OurAgentsTel}>{item.OurAgentsTel}</OurAgentsTel>
                                            <OurAgentsSocial href="#" aria-label="Facebook Link">
                                                <FbIcon/>
                                            </OurAgentsSocial>
                                            <OurAgentsSocial href="#" aria-label="Twitter Link">
                                                <TwitterIcon/>
                                            </OurAgentsSocial>
                                            <OurAgentsSocial href="#" aria-label="LinkedIn Link">
                                                <LinkedinIcon/>
                                            </OurAgentsSocial>
                                            <OurAgentsSocial href="#" aria-label="Instagram Link">
                                                <InstaIcon/>
                                            </OurAgentsSocial>
                                        </OurAgentsDescLayout>
                                    </OurAgentsLayout>
                                </OurAgentsSliderCol>
                            );
                            })
                        }
                        </Slider>
                        <ImgButtonRight onClick={this.next} aria-label="Next Button">
                            <RightIcon />
                        </ImgButtonRight>
                    </SliderOuterWrapper>
                </Container>
            </OurAgentsSection>
        );
    }
}

const OurAgents = () => (
    <StaticQuery
        query={graphql`{
  realestatePage2Json {
    OurAgents {
      DelayConstant
      Heading
      Border
      OurAgentsSlider {
        OurAgentsSliderImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        OurAgentsName
        OurAgentsTel
      }
    }
  }
}
`}
        render={(data) => (
            <OurAgentsPage OurAgentsData={data.realestatePage2Json.OurAgents}/>
        )}
    />
)
export default OurAgents;