import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AmenitiesSection,
    HeadingLayout,
    Heading,
    Border,
    AmenitiesLayout,
    AmenitiesTextLayout
} from './amenities.style';

import { useStaticQuery, graphql } from 'gatsby';

const Amenities = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        realestatePage2Json{
            Amenities{
                Heading
                Border
                AmenitiesData{
                    AmenitiesText
                }
            }
        }
    }
  `);
  const AmenitiesData = JSONData.realestatePage2Json.Amenities;

    return(
        <AmenitiesSection id="amenitiesSection">
            <Container>
                <HeadingLayout>
                    <Heading>
                    {AmenitiesData.Heading} 
                    </Heading>
                    <Border src={AmenitiesData.Border} alt=""/>
                </HeadingLayout>
                <Row>
                {
                    AmenitiesData.AmenitiesData.map((amnObj, idx) => {
                    return <Col lg={3} md={4} sm={6} xs={6}>
                        <AmenitiesLayout position={idx+1}>
                            <AmenitiesTextLayout>
                                <h6>
                                {amnObj.AmenitiesText}
                                </h6>
                            </AmenitiesTextLayout>
                        </AmenitiesLayout>
                    </Col>
                    })
                }
                </Row>
            </Container>
        </AmenitiesSection>

    );
}

export default Amenities;