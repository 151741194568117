import React,{Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {GallerySection,GalleryImageWrapper,ImageHolder} from './gallery.style';
import { StaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import { GatsbyImage } from "gatsby-plugin-image";

class Gallery extends Component {
    
    openDialogBox(imageArray,idx)
    {
        this.props.openLightBox(imageArray,idx);
    }
    render() {
        return (
            <GallerySection id="gallerySection">
                    <Fade bottom delay={1*this.props.GalleryData.DelayConstant}>
                        <GalleryImageWrapper>
                        {
                            this.props.GalleryData.GalleryImageWrapper.map((item,idx) => {
                                return (
                                    <ImageHolder onClick={this.openDialogBox.bind(this,this.props.GalleryData,idx)}>
                                        <GatsbyImage
                                            image={item.GalleryImage.childImageSharp.gatsbyImageData}
                                            imgStyle={{
                                                marginBottom:"0px",
                                            }}
                                            alt="" />
                                    </ImageHolder>
                                );
                            })
                        }
                        </GalleryImageWrapper>
                    </Fade>
            </GallerySection>
        );
        }
    }

    export default props => (
    <StaticQuery
        query={graphql`{
  realestatePage2Json {
    Gallery {
      DelayConstant
      GalleryImageWrapper {
        GalleryImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
          publicURL
        }
      }
    }
  }
}
`}
        render={(data) => (
            <Gallery 
            GalleryData={data.realestatePage2Json.Gallery}
            {...props}
            />
        )}
    />
    )