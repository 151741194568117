import styled from 'styled-components';
import {SectionHeading} from '../Common/common.style';
import {device} from '../Common/device'
import 'bootstrap/dist/css/bootstrap.min.css';
import {QuoteLeft} from '@styled-icons/fa-solid/QuoteLeft';

export const TestimonialsSection = styled.section`
    padding:100px 0px;
    background:#f5f5f5;

    @media ${device.tablet} {
        padding:80px 0px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:25px;
    text-align:center;
`;

export const Heading = styled(SectionHeading)`
    
`;

export const Border = styled.img`
    height:15px;
    vertical-align:top;
    line-height:1;
`;

export const SliderOuterWrapper = styled.div`
    margin-left:-15px;
    margin-right:-15px;
`;

export const CardOuterWrapper = styled.div`
    outline:0;
`;

export const Card = styled.div`
    margin:40px 15px 15px;
    background:#fff;
    border-radius:5px;
    box-shadow: 0px 0px 10px 0px #d0d0d0;
    overflow:hidden;

    @media ${device.laptop} {
        margin:15px;
    }

    @media ${device.tablet} {
        margin:10px;
    }

    .slick-center &{
        margin:15px;
        
        @media ${device.tablet} {
            margin:10px;
        }
    }
`;

export const TopCardLayout = styled.div`
    padding:25px;
    p{
        margin-bottom:0px;
    }
`;

export const QuotesHolder = styled.div`

`;

export const QuotesIcon = styled(QuoteLeft)`
    width: 40px;
    color:#f4ba10;
    margin-bottom:10px;
`;

export const BottomCardLayout = styled.div`
    padding:25px;
    background:#f8f8f8;
    border-top:1px solid #ebebeb;
`;
export const CardProfileLayout = styled.div`
    display:flex;
    align-items:center;
`;

export const ProfilePicLayout = styled.div`
    flex-shrink:0;

    .ProfilePic{
        width:60px;
        border-radius:100%;
        @media ${device.tablet} {
            width:40px;
        }
    }
`;

export const ProfileNameLayout = styled.div`
    margin-left:10px;

    p{
        line-height:1;
        margin-bottom:0px;
    }

    h5{
        margin-bottom:5px;
        line-height:1;
        text-align:left;

        @media ${device.tablet} {
            line-height:1;
        }
    }
`;


export const NextPrev = styled.div`
    text-align:center;
    line-height:0px;
    margin-top: 25px;
`;

export const ImgButton = styled.button`
    line-height:0px;
    padding-bottom:0px;
    border:none;
    background:none;
    outline:none;

    :focus{
        outline:0
    }
`;

export const PrevImg = styled.img`
    margin:0px 5px;
    cursor:pointer;
    height:24px;
    transition:all .4s;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }

    @media ${device.tablet} {
        height:20px;
    }
`;

export const NextImg = styled.img`
    margin:0px 5px;
    cursor:pointer;
    transition:all .4s;
    height:24px;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
    @media ${device.tablet} {
        height:20px;
    }   
`;