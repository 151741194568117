import React from 'react';
import {FeaturesSection,HeadingLayout,MainHeading,Border,
    FeaturesSingle,FeaturesImg
} from './features.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const Features = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                realestatePage2Json{
                    Features{
                        Heading
                        Border
                        FeaturesImg1
                        FeaturesHeading1
                        FeaturesDesc1
                        FeaturesImg2
                        FeaturesHeading2
                        FeaturesDesc2
                        FeaturesImg3
                        FeaturesHeading3
                        FeaturesDesc3
                    }
                }
            }
    `);
    const FeaturesData = JSONData.realestatePage2Json.Features;
        return (
        <FeaturesSection id="featuresSection">
            <Container>
                <HeadingLayout>
                    <MainHeading>
                    {FeaturesData.Heading} 
                    </MainHeading>
                    <Border src={FeaturesData.Border} alt=""/>
                </HeadingLayout>
                <Row>
                    <Col lg={4} md={4}>
                        <FeaturesSingle>
                            <FeaturesImg 
                                src={FeaturesData.FeaturesImg1} 
                                alt=""
                            />
                            <h5>
                            {FeaturesData.FeaturesHeading1} 
                            </h5>
                            <p>
                            {FeaturesData.FeaturesDesc1}
                            </p>
                        </FeaturesSingle>
                    </Col>
                    <Col lg={4} md={4}>
                        <FeaturesSingle>
                            <FeaturesImg 
                                src={FeaturesData.FeaturesImg2} 
                                alt=""
                            />
                            <h5>
                            {FeaturesData.FeaturesHeading2} 
                            </h5>
                            <p>
                            {FeaturesData.FeaturesDesc2}
                            </p>
                        </FeaturesSingle>
                    </Col>
                    <Col lg={4} md={4}>
                        <FeaturesSingle>
                            <FeaturesImg 
                                src={FeaturesData.FeaturesImg3} 
                                alt=""
                            />
                            <h5>
                            {FeaturesData.FeaturesHeading3} 
                            </h5>
                            <p>
                            {FeaturesData.FeaturesDesc3}
                            </p>
                        </FeaturesSingle>
                    </Col>
                </Row>
            </Container>
        </FeaturesSection>
    );
}

export default Features;