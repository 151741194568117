import styled from 'styled-components';
import {SectionHeading,Commonbtn} from '../Common/common.style';
import {device} from '../Common/device';
import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook';
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter';
import {SocialLinkedin} from '@styled-icons/typicons/SocialLinkedin';
import {SocialInstagram} from '@styled-icons/typicons/SocialInstagram';
import BackgroundImg from '../../../assets/realestate-2-images/footer-image.jpg'

export const FooterSection = styled.section`

`;

export const FooterWrapper = styled.div`
    display:flex;

    @media ${device.laptop} {
        flex-direction:column;
    }
`;

export const FooterSingleFirst = styled.div`
    width: 33.33%;
    flex-shrink:0;
    background-image:url(${BackgroundImg});
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: calc(50% - 660px);

    @media ${device.laptopL} {
        padding-left: calc(50% - 570px);
    }
 
    @media ${device.laptopM} {
        padding-left: calc(50% - 480px);
    }
 
    @media ${device.laptop} {
        padding-left:0;
        width:100%;
        margin:auto;
        text-align:center;
        justify-content:center;
    }

    @media ${device.tablet} {
        padding:0px 10px;
    }
`;

export const ContactUsLayout = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding-right:40px;

    @media ${device.laptop} {
        padding:60px 15px;
        max-width:720px;
        margin:auto;
    }

    @media ${device.tablet} {
        max-width:540px;
    }
    
`;

export const ContactInfoLayout = styled.div`
    @media ${device.laptop} {
        width:100%;
    }
`;

export const CompanyLogo = styled.img`
    width: 170px;
    margin-bottom: 30px;
`;

export const DetailsLayout = styled.div`
    display:flex;
    align-items:flex-start;
    margin-bottom:20px;
    p{
        color:#fff;
        margin-bottom:0px;
        margin-left:15px;
    }

    @media ${device.laptop} {
        justify-content:center;
    }
`;

export const DetailsIcon = styled.img`
    width:20px;
`;

export const SocialLayout  = styled.div`

`;

export const FooterSocial = styled.a`
    margin-right:10px;
`;

export const InstaIcon = styled(SocialInstagram)`
    width: 32px;
    color: #282828;
    background: rgba(244, 186, 16, .9);
    padding: 6px;
    border-radius:100%;
    margin: 10px 0px 0px;
    transition:all .5s;
    :hover{
        color:#fff;
    }
`;

export const LinkedinIcon = styled(SocialLinkedin)`
    width: 32px;
    color: #282828;
    background: rgba(244, 186, 16, .9);
    padding: 6px;
    border-radius:100%;
    margin: 10px 0px 0px;
    transition:all .5s;
    :hover{
        color:#fff;
    }
`;

export const TwitterIcon = styled(SocialTwitter)`
    width: 32px;
    color: #282828;
    background: rgba(244, 186, 16, .9);
    border-radius:100%;
    padding: 6px;
    margin: 10px 0px 0px;
    transition:all .5s;
    :hover{
        color:#fff;
    }
`;

export const FbIcon = styled(SocialFacebook)`
    width: 32px;
    color: #282828;
    background: rgba(244, 186, 16, .9);
    border-radius:100%;
    padding: 6px;
    margin: 10px 0px 0px;
    transition:all .5s;
    :hover{
        color:#fff;
    }
`;

export const FooterSingle = styled.div`
    width: 33.33%;
    flex-shrink:0;

    @media ${device.laptop} {
        width:100%;
        margin:auto;
    }

    @media ${device.tablet} {
        padding:0px 10px;
    }
`;

export const ContactLayout = styled.div`
    padding:60px 40px;

    @media ${device.laptop} {
        padding:60px 15px;
        max-width:720px;
        margin:auto;
    }

    @media ${device.tablet} {
        max-width:540px;
    }
`;

export const FooterBg = styled.img`

`;

export const HeadingLayout = styled.div`
    margin-bottom:30px;
    text-align:center;
`;

export const Heading = styled(SectionHeading)`
    
`;

export const Border = styled.img`
    height:15px;
    vertical-align:top;
    line-height:1;
`;

export const FormLayout = styled.div`

`;

export const Form = styled.form`
    margin-bottom:0px;
`;

export const FormGroup = styled.div`
    margin-bottom:30px;
`;

export const InputText = styled.input`
    width: 100%;
    border:none;
    border-bottom: 1px solid #959595;
    padding: 8px 0px;
    outline:0;
    font-size: 15px;
`;

export const ContactSpanErr = styled.span`
    color:red;
    font-size:14px;
`;

export const TextareaMessage = styled.textarea`
    width: 100%;
    border:none;
    border-bottom: 1px solid #959595;
    padding: 8px 0px;
    height:100px;
    font-size: 15px;
    outline:0;
`;

export const TextCenter = styled.div`
    text-align:center;
`;

export const SubmitBtn = styled(Commonbtn)`
    color:#fff;

    :hover{
        color:#fff;
        text-decoration:none;
    }
`;

export const ContactSpanSuccess = styled.span`
    color:green;
    font-size:14px;
`;

export const FooterSingleLast = styled.div`
    background: #f4ba10;
    width: 33.33%;
    flex-shrink:0;

    display:flex;
    align-items:center;

    @media ${device.laptopL} {
        padding-right: calc(50% - 570px);
    }
 
    @media ${device.laptopM} {
        padding-right: calc(50% - 480px);
    }
 
    @media ${device.laptop} {
        padding-right:0;
        width:100%;
        margin:auto;
    }

    @media ${device.tablet} {
        padding:0px 10px;
    }

    
`;

export const QuickLinksLayout = styled.div`
    display:flex;
    padding-left:40px;

    @media ${device.laptop} {
        padding:60px 15px;
        max-width:720px;
        margin:auto;
    }

    @media ${device.tablet} {
        max-width:540px;
    }

    @media ${device.mobileS} {
        flex-direction:column;
    }
`;

export const FooterUl = styled.ul`
    margin: 0;
    text-align: left;

    @media ${device.laptop} {
        text-align:center;
    }
`;

export const FooterLi = styled.li`
    list-style:none;
    margin-bottom: 20px;
    padding-right:20px;

    @media ${device.laptop} {
        padding:0px 20px;
    }

    @media ${device.mobileM} {
        padding:0px 15px;
    }

    a{
        color:#fff;

        :hover{
            text-decoration:underline;
        }
    }
`;

export const FooterBottomLayout = styled.div`
    background:#fafafa;
    padding:20px;
`;

export const BottomFooterPara = styled.p`
    color:#000;
    margin-bottom:0px;
    font-size:15px;
    text-align:center;
`;

export const BottomLink = styled.a`
    font-size:16px;   
    margin-bottom:0px;
    color:#000;
    text-decoration:none;
    text-align:center;

    :hover{
        text-decoration:underline;
    }
`;

